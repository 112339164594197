* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Rubik, sans-serif;
  background-color: #212529;
}

/* Centers container */
.container {
  /* margin-left: auto;
  margin-right: auto; */
  margin: 100px 0 auto;
  /*max-width: 960px;*/
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
}
